import './Publications.css';
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import { Editor } from '@tinymce/tinymce-react';
import 'react-quill/dist/quill.snow.css';
import {api} from "../../utils/api";

export default function Publications({modifyTemplatesMode}) {
    let [isLoading, setIsLoading] = useState(false);
    const [editableValue, setEditableValue] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    
    useEffect(()=>{
        setIsLoading(true);
        api()
            .getEmbeddedContent()
            .then(content => {
                setEditableValue(content);
                setIsLoading(false);
            })
    }, []);

    return <div>
    <div className={'news-page'}>
      <Grid className={'header'}>
        <Grid.Row>
          <Grid.Column width={8}><h1>Объявления</h1></Grid.Column>
          <Grid.Column width={8} style={{textAlign: 'right'}}>
              {modifyTemplatesMode ? <Button onClick={()=>{setIsEditable(!isEditable)}} className={'edit-btn'}><img src={'/images/edit.svg'}/></Button> : ''}
          </Grid.Column>
        </Grid.Row>
      </Grid>
        {isLoading ? <div className="loader">Loading...</div> : ''}
        {isEditable ? <div><Editor
            apiKey={'h85ip231n58fyx29vbxrxkmq5sffbv1q53l6cqxogr2p3xgd'}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'link',
                    'save',
                    'textcolor',
                    'colorpicker',
                    'help',
                    'nonbreaking',
                    'lists',
                    'advlist',
                    'contextmenu',
                    'autolink',
                    'visualblocks',
                    'paste',
                    'hr',
                    'table',
                    'searchreplace',
                    'code',
                    'imagetools'
                ],
                toolbar: ['save | styleselect  removeformat visualblocks | fontsizeselect bold italic underline | forecolor backcolor | table |  hr | alignleft aligncenter alignright alignfull | bullist numlist outdent indent | code | help'],
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
            }}
            value={editableValue}
            onEditorChange={setEditableValue}/>
        <Button onClick={()=>{
            api()
                .setEmbeddedContent(editableValue)
                .then(() => {
                    setIsEditable(false);
                })
        }}>Сохранить</Button>
        </div> : <div className={'editable-content'} dangerouslySetInnerHTML={{__html: editableValue}} />}
    </div>
  </div>;
}