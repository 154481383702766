import './FaqAccordion.css';
import {useState} from "react";

export default function FaqAccordion({items}) {
  let [openItemIndex, setOpenItemIndex] = useState(null);

  return <div className={'faq-accordion-container'}>
    {items.map((item, index) => <div
        key={index}
        className={'faq-accordion-item-container' + (openItemIndex === index ? ' is-open' : '')}
        onClick={()=>{
          setOpenItemIndex(oldIndex => {return  index === oldIndex ? null : index})
        }}>
      <div className={'faq-accordion-item-title'}>{item.question}</div>
      <img className={'faq-accordion-indicator'} src={'/images/' + (openItemIndex === index ? 'minus' : 'plus') + '.svg'}/>
      {openItemIndex === index ? <div className={'faq-accordion-item-content'} dangerouslySetInnerHTML={{__html: item.answer}}></div> : ''}
    </div>)}
  </div>;
}
