import { Grid, Modal } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import CustomInput from "../../shared/CustomInput/CustomInput";
import CustomPassword from "../../shared/CustomPassword/CustomPassword";
import { api } from "../../utils/api";
import { Contacts } from "./Contacts";

import './Settings.css';


const Settings = ({ phone, email, userId, userFirstName, userLastName, initLogin, onChangeLogin }) => {
  let [login, setLogin] = useState(initLogin);
  let [newPassword, setNewPassword] = useState('');
  let [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  let [openRemoveAccount, setOpenRemoveAccount] = useState(false);
  let [personalConfirmationText, setPersonalConfirmationText] = useState('');

  let [afterChangePasswordModal, setAfterChangePasswordModal] = useState(false);
  let [afterChangeLoginModal, setAfterChangeLoginModal] = useState(false);
  let [afterChangeLoginModalText, setAfterChangeLoginModalText] = useState('');
  let [loginOrPasswordLengthError, setLoginOrPasswordLengthError] = useState(null);

  useEffect(() => {
    setLogin(initLogin);
  }, [initLogin]);

  useEffect(() => {
    api()
      .getPersonalDataText()
      .then((resp) => {
        setPersonalConfirmationText(resp.Descr);
      });
  }, []);

  const removeAccount = () => {
    api()
      .removeAccount()
      .then(() => {
        setOpenRemoveAccount(false);
        localStorage.clear();
        window.location = '/';
      });
  };

  const saveLoginOrChangePassword = () => {
    if (login !== initLogin) {
      if (login.length > 15) {
        setLoginOrPasswordLengthError('Максимальная длина логина 15 символов');
        return;
      }

      api()
        .changeLogin(login)
        .then((resp) => {
          if (resp.ErrorInfo !== null && resp.ErrorInfo !== undefined && resp.ErrorInfo !== '') {
            toast.error(resp.ErrorInfo);
            setLogin(initLogin);
          } else {
            toast.success('Логин уcпешно изменён!');
            onChangeLogin();
          }
        });
    }

    if (newPassword !== '' && newPassword === newPasswordRepeat) {
      if (newPassword.length > 15) {
        setLoginOrPasswordLengthError('Максимальная длина пароля 15 символов');
        return;
      }
      if (newPassword.length < 5) {
        setLoginOrPasswordLengthError('Минимальная длина пароля 5 символов');
        return;
      }

      api()
        .changePassword(newPassword)
        .then(() => {
          setNewPassword('');
          setNewPasswordRepeat('');
          toast.success('Пароль уcпешно изменён!');
        });
    }
  };

  return (
    <div>
      <div className={'settings-page'}>
        <Grid className={'header'}>
          <Grid.Row>
            <Grid.Column width={8}><h1>Настройки</h1></Grid.Column>
          </Grid.Row>
        </Grid>

        <div className={'settings-page-container'}>
          <div className={'custom-left-panel'}>
            <div className={'titled-block'}>
              <div className={'title'}>Личные данные:</div>
              <div className={'content'}>
                <CustomInput disabled label={'Фамилия'} value={userLastName}/>
                <CustomInput disabled label={'Имя'} value={userFirstName}/>
                <CustomInput disabled label={'Телефон'} value={phone} />
                <CustomInput disabled label={'Email'} value={email}/>
                <div className={'user-id'}>ID: {userId}</div>
              </div>
            </div>

            {/*<div className={'titled-block'}>*/}
            {/*  <div className={'title'}>Способы коммункации:</div>*/}
            {/*  <div className={'content communication-settings-block'}>*/}
            {/*    <Grid>*/}
            {/*      <Grid.Row>*/}
            {/*        <Grid.Column width={6}>Письмо Email</Grid.Column>*/}
            {/*        <Grid.Column width={8}><Checkbox toggle checked={useEmail} onChange={()=>{setUseEmail(!useEmail)}} /></Grid.Column>*/}
            {/*      </Grid.Row>*/}
            {/*      <Grid.Row>*/}
            {/*        <Grid.Column width={6}>SMS</Grid.Column>*/}
            {/*        <Grid.Column width={8}><Checkbox toggle checked={useSms} onChange={()=>{setUseSms(!useSms)}} /></Grid.Column>*/}
            {/*      </Grid.Row>*/}
            {/*      <Grid.Row>*/}
            {/*        <Grid.Column width={6}>Мобильный телефон</Grid.Column>*/}
            {/*        <Grid.Column width={8}><Checkbox toggle checked={usePhone} onChange={()=>{setUsePhone(!usePhone)}} /></Grid.Column>*/}
            {/*      </Grid.Row>*/}
            {/*    </Grid>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={'titled-block'}>
              <div className={'title'}>
                Смена логина и пароля:
              </div>

              <div className={'content'}>
                <div className={'custom-title'}>Логин</div>
                <CustomInput
                  label={'Укажите логин...'}
                  value={login}
                  onChange={setLogin}
                  onFocus={() => setLoginOrPasswordLengthError(null)}
                />

                <div className={'custom-title'}>Пароль</div>
                <CustomPassword
                  label={'Новый пароль'}
                  value={newPassword}
                  onChange={setNewPassword}
                  onFocus={() => setLoginOrPasswordLengthError(null)}
                />
                <CustomPassword
                  label={'Подтвердите пароль'}
                  value={newPasswordRepeat}
                  onChange={setNewPasswordRepeat}
                  onFocus={() => setLoginOrPasswordLengthError(null)}
                />
              </div>
            </div>

            {loginOrPasswordLengthError !== null && (
              <div style={{color: 'red', paddingTop: '15px'}}>
                {loginOrPasswordLengthError}
              </div>
            )}

            <button
              disabled={!(login !== initLogin || (newPassword !== '' && newPassword === newPasswordRepeat))}
              className={'save-btn'}
              onClick={saveLoginOrChangePassword}
            >
              Сохранить
            </button>

            <Contacts />
          </div>

          <div className={'custom-right-panel'}>
            <div className={'titled-block'}>
              <div className={'title'}>&nbsp;</div>

              <div className={'content'}>
                {personalConfirmationText.split('\r\n').map((line, i) => (
                  <p key={i}>
                    {line}
                  </p>
                ))}
              </div>
            </div>

            <button className={'remove-account-btn'} onClick={() => setOpenRemoveAccount(true)}>
              <img src={'/images/person-remove.svg'} alt="delete-account"/>Удалить аккаунт
            </button>
          </div>
        </div>

        <Modal
          onClose={() => setOpenRemoveAccount(false)}
          onOpen={() => setOpenRemoveAccount(true)}
          open={openRemoveAccount}
          className={'remove-account-modal'}
        >
          <Modal.Content>
            <div className={'title'}>Внимание</div>
            <p>Вы действительно хотите<br/> удалить аккаунт? </p>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} onClick={() => setOpenRemoveAccount(false)}>
                  Нет
                </Grid.Column>
                <Grid.Column width={8} onClick={removeAccount}>
                  Да
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>

        <Modal
          onClose={() => setAfterChangePasswordModal(false)}
          onOpen={() => setAfterChangePasswordModal(true)}
          open={afterChangePasswordModal}
          className={'remove-account-modal'}
        >
          <Modal.Content>
            <div className={'title'}>
              Пароль успешно изменён!
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          onClose={() => setAfterChangeLoginModal(false)}
          onOpen={() => setAfterChangeLoginModal(true)}
          open={afterChangeLoginModal}
          className={'remove-account-modal'}
        >
          <Modal.Content>
            <div className={'title'}>
              {afterChangeLoginModalText}
            </div>
          </Modal.Content>
        </Modal>
      </div>
    </div>
  );
}

export default Settings
