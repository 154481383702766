import './News.css';
import {useNavigate} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import StackGrid from "react-stack-grid";
import {useWindowSize} from "../../utils/useWindowSize";
import {api} from "../../utils/api";

export default function News() {
    let [news, setNews] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    let size = useWindowSize();
    let navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        api()
            .getLastNews()
            .then((resp) => {
                let newsFromServer = resp.map((newsItem) => ({
                    id: newsItem.NewsId,
                    isNew: !newsItem.IsReaded,
                    isImportant: newsItem.IsImportant,
                    image: 'data:image/png;base64,' + newsItem.NewsImage1,
                    link: newsItem.NewsUrl,
                    title: newsItem.Title,
                    newsTypeDescription: newsItem.NewsCategoryDescr,
                    date: newsItem.NewsDate,
                    text: newsItem.Content
                }))
                setNews(newsFromServer)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const newsElements = news
        .filter((x) => x.isNew)
        .map((newsItem) => (
            <div
                key={newsItem.id}
                className={'news-item'} style={{cursor: 'pointer'}}
                onClick={() => navigate('/news-item/' + newsItem.id)}
            >
                <img className={'main-img'} src={newsItem.image}/>
                <h2>{newsItem.title}</h2>
                <a
                    onClick={() => navigate('/news-item/' + newsItem.id)}
                    className={'view-news-item-link'}>
                    Подробнее<img src={window.config.theme === 'Dark' ? '/images/dark/arrow-right.svg' : '/images/arrow-right.svg'}/>
                </a>
            </div>
        ))

    return (
        <div className={'news-page'}>
            <Grid className={'header'}>
                <Grid.Row>
                    <Grid.Column width={8}>
                      <h1>Новости</h1>{news.filter((x) => x.isNew).length > 0 && <label>{news.filter((x) => x.isNew).length}</label>}
                    </Grid.Column>
                    <Grid.Column width={8} style={{textAlign: 'right'}}>
                        <Button
                            onClick={() => navigate('/all-news')}
                            className={'open-all-news-btn'}
                        >
                          <img src={'/images/newspaper.svg'}/>
                          <span className={'for-desktop'}> Все новости</span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className={'news-items'}>
                {news.filter((x) => x.isNew).length === 0 && !isLoading && (
                    <div style={{textAlign: 'center', paddingTop: '30px'}}>
                        <div>
                            <img src={'/images/empty.svg'}/>
                        </div>
                        <div className={'empty-message'}>Новостей нет</div>
                    </div>
                )}

                {isLoading ? <div className="loader">Loading...</div> : ''}

                <div className={'for-mobile'}>
                    {newsElements}
                </div>

                <StackGrid
                    columnWidth={(size.width - 360)/2}
                    horizontal={false}
                    gutterHeight={0}
                    gutterWidth={0}
                    style={{padding: '0px'}}
                    className={'for-desktop'}
                >
                    {newsElements}
                </StackGrid>
            </div>
        </div>
    );
}
