import './ReopenChatModal.css';
import {Grid, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CustomTextarea from "../../../../shared/CustomTextarea/CustomTextarea";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";

export default function ReopenChatModal({open, setOpen, onReopenChat, activeChatId}) {
    let [message, setMessage] = useState('');

  useEffect(()=>{
    if(!open) {
      setMessage('');
    }
  }, [open]);

  let send = () => {
    api()
      .reopenChatForDealer(activeChatId, message)
      .then(resp=>{
        if(resp.ErrorInfo != '' && resp.ErrorInfo != null){
          toast.error(resp.ErrorInfo);
        }else{
          setOpen(false);
          onReopenChat();
          toast.info(resp.UserInfo);
        }
      });
  };

  return (
    <Modal
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
    className={'reopen-chat-modal'}
  >
    <Modal.Content>
      <div className={'title'}>Укажите, пожалуйста, причину возврата обращения дилеру</div>
      <CustomTextarea value={message} label={'Укажите Ваш комментарий'} onChange={setMessage}/>
        <Grid>
          <Grid.Row>
            <Grid.Column
              width={8}
              onClick={() => {
                setOpen(false)
             }}>
              Отмена
            </Grid.Column>
            <Grid.Column
              width={8}
              onClick={send}>
              Сохранить
            </Grid.Column>
          </Grid.Row>
        </Grid>
    </Modal.Content>
  </Modal>
  );
}
