import './Dealer.css';
import {Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {Map, Placemark, YMaps} from 'react-yandex-maps';
import {api} from "../../utils/api";
import SelectDealerModal from "../../shared/SelectDealerModal/SelectDealerModal";

export default function Dealer({onChangeDealer}) {
  let [marketingItems, setMarketingItems] = useState([]);
  let [dealerSelectModal, setDealerSelectModal] = useState(false);
  let [dealerName, setDealerName] = useState('');
  let [dealerAddition, setDealerAddition] = useState('');
  let [dealerAddress, setDealerAddress] = useState('');
  let [dealerCoords, setDealerCoords] = useState([55.75, 37.57]);
  let [dealerContacts, setDealerContacts] = useState([]);
  
  let [viewMap, setViewMap] = useState(false);

  let loadMyDealer = () => {
    api()
        .getCurrentDealerInfo()
        .then(dealer => {
          setDealerName(dealer.CommonInfo.Descr);
          setDealerAddition(dealer.CommonInfo.ContactNotes);
          setDealerAddress(dealer.CommonInfo.Address);
          setDealerCoords([dealer.CommonInfo.MapCoord1, dealer.CommonInfo.MapCoord2]);
          setViewMap(false);
          setTimeout(()=>{setViewMap(true);}, 0);
          setDealerContacts(dealer.ContactList.map(x=> ({
            type: x.GroupId,
            text: x.Info,
            notes: x.Notes,
          })));
          api()
              .getDealerMarketingActions()
              .then(actions => {
                setMarketingItems(actions.map(action => ({
                  image: 'data:image/png;base64,' + action.ActionImage,
                  title: action.Descr,
                  text: action.Notes,
                  date: '',
                })));
              });
        });
  }
  
  useEffect(()=>{
    loadMyDealer();
  }, []);

  let getImageForContactType = (type)=>{
    switch (type){
      case 2: return 'phone';
      case 3: return 'mail';
      case 4: return 'globe';
      default: return '';
    }
  };
  let getElementForContactType = (type, content)=>{
    switch (type){
      case 2: return <>{content}</>;
      case 3: return <a style={{color: window.config.theme == 'white' ? 'black' : '#f5f5f5'}} href={'mailto:' + content}>{content}</a>;
      case 4: return <a style={{color: window.config.theme == 'white' ? 'black' : '#f5f5f5'}} href={(content.includes('http') ? '' : 'http://') + content} target={'_blank'}>{content}</a>;
      default: return <>{content}</>;
    }
  };
  
  return <div>
    <div className={'my-dealer-page'}>
      <Grid className={'header'}>
        <Grid.Row>
          <Grid.Column width={8}><h1>Мой дилер</h1></Grid.Column>
          <Grid.Column width={8}><button onClick={() => {
            setDealerSelectModal(true);
          }}>Выбрать другого дилера</button></Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={'current-dealer-info-container'}>
          <div className={'current-dealer-place-info-block'}>
            <div className={'title'}>{dealerName}</div>
            <div className={'address'}><img src={'/images/place-pin.svg'}/> {dealerAddress}</div>
            <div className={'map-container'}>
              {viewMap ? <YMaps style={{width: '100%'}}>
                  <div>
                      <Map defaultState={{ center: dealerCoords, zoom: 9 }} height={'300px'} width={'490px'}>
                          <Placemark defaultGeometry={dealerCoords} options={{
                              iconLayout: "default#image",
                              iconImageHref: '/images/' + window.config.mapPinImageName,
                              iconImageSize: [28, 45],
                          }}
                                     onClick={()=>{
                                     }}
                          />
                      </Map>
                  </div>
              </YMaps> : ''}
            </div>
          </div>
          <div className={'contacts-container'}>
              <Grid>
                  {dealerContacts.map(contact => <Grid.Row>
                      <Grid.Column width={8} style={{paddingTop: '0px', paddingBottom: '0px'}}><img src={'/images/'+getImageForContactType(contact.type)+'.svg'}/>{getElementForContactType(contact.type, contact.text)}</Grid.Column>
                      <Grid.Column width={8} style={{paddingTop: '0px', paddingBottom: '0px'}}>{contact.notes !== null && contact.notes !== '' && contact.Notes !== contact.text ? <div className={'note'}>{contact.notes}</div> : ''}</Grid.Column>
                  </Grid.Row>)}
              </Grid>
          </div>
      </div>
      {dealerAddition !== undefined && dealerAddition !== null && dealerAddition !== ''  ? <>
        <div className={'block-title'}>Дополнительно</div>
        <div className={'addition-info-block'}>
          {dealerAddition}
        </div>
      </> : ''}
      {marketingItems.length > 0 ? <>
        <div className={'block-title'}>Текущие маркетинговые акции</div>
        <div className={'marketing-items'}>
          {marketingItems.map(marketingItem => <div className={'marketing-item'}>
            <div className={'item-image'}><img src={marketingItem.image} /></div>
            <div className={'item-title'}>{marketingItem.title}</div>
            <div className={'item-text'}>{marketingItem.text}</div>
            {/*<div className={'item-date'}><img src={'/images/black-watch.svg'}/> {marketingItem.date}</div>*/}
          </div>)}
        </div>
      </> : <div className={'empty-marketing'}>Дилер не предоставил информации о текущих акциях</div>}
    </div>
    <SelectDealerModal 
        open={dealerSelectModal} 
        setOpen={setDealerSelectModal} 
        onChange={()=>{
          loadMyDealer();
          onChangeDealer();
        }}
        dealerName={dealerName}
    />
  </div>;
}