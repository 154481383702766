import './Faq.css';
import {Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {api} from "../../../utils/api";
import FaqAccordion from "./components/FaqAccordion";

export default function Faq({isOpen, setOpen}) {
  let [questionGroups, setQuestionGroups] = useState([]);
  
  useEffect(() => {
    if (!isOpen) {
      return
    }

    api()
        .getFaq()
        .then(resp=>{
          if(resp.length > 0)
            setQuestionGroups(resp.map(x=> {return {
              title: x.Descr,
              questions: x.Questions.map(q=> ({
                question: q.Question,
                answer: q.Answer,
              }))
            }}));
        });
  }, [isOpen]);

  return <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      closeIcon={true}
      closeOnEscape={true}
      closeOnTriggerBlur={true}
      className={'faq-modal'}
  >
    <Modal.Content>
      <div className={'title'}>Вопросы и ответы</div>
      <div className={'question-groups-container'}>
        {questionGroups != null && questionGroups.length > 0 ? questionGroups.map((questionGroup,index) => <div key={index} className={'question-group-container'}>
          <div className={'group-name'}>{questionGroup.title}</div>
          <FaqAccordion items={questionGroup.questions} />
        </div>) : 'Нет данных для отображения'}
      </div>
    </Modal.Content>
  </Modal>;
}
