import './NewsItem.css';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import {api} from "../../utils/api";

export default function NewsItem() {
    let {id} = useParams();
    let [newsItem, setNewsItem] = useState(null);
    let [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    let load = () => {
        setIsLoading(true);
        api()
            .getNewsItem(id)
            .then(resp=>{
                setNewsItem({
                    id: resp.NewsId,
                    isNew: !resp.IsReaded,
                    isImportant: resp.IsImportant,
                    image: 'data:image/png;base64,' + resp.NewsImage1,
                    link: resp.NewsUrl,
                    title: resp.Title,
                    newsTypeDescription: resp.NewsCategoryDescr,
                    date: resp.NewsDate,
                    text: resp.Content,
                    previousNewsId: resp.PreviousNewsId,
                    nextNewsId: resp.NextNewsId,
                });
                setIsLoading(false);
            })
    };

    useEffect(load, [id]);

    if (newsItem == null) {
        return <div className="loader">Loading...</div>;
    }

    let toArhiveBtn = newsItem.isNew && <Button onClick={() => {
        api()
            .markAsRead(newsItem.id)
            .then(()=>{
                let isGoBack = window.location.search === '?goBack';
                let isGoFront = window.location.search === '?goFront';
                if(newsItem.previousNewsId !== null && !isGoBack){
                    navigate('/news-item/' + newsItem.previousNewsId + '?goFront');
                    return;
                }
                if(newsItem.nextNewsId !== null && !isGoFront) {
                    navigate('/news-item/' + newsItem.nextNewsId + '?goBack');
                    return;
                }
                navigate('/news');
            });
    }}>В архив</Button>;

    return (
    <div className={'news-item-page'}>
        <div style={{position: 'absolute', width: '80%', textAlign: 'center', top: '-20px'}}>
            {isLoading && <div className="loader">Loading...</div>}
        </div>
      <Grid className={'header'}>
        <Grid.Row>
          <Grid.Column width={8} style={{paddingTop: '18px'}}>
              <img
                  onClick={() => navigate('/news')}
                  src={window.config.theme === 'Dark' ? "/images/dark/arrow-back.svg" : "/images/arrow-back.svg"}
              />
          </Grid.Column>
          <Grid.Column width={8} style={{textAlign: 'right'}}>
              <Button onClick={() => navigate('/all-news')} className={'open-all-news-btn'}>
                  <img src={'/images/newspaper.svg'}/>
                  <span className={'for-desktop'}> Все новости</span>
              </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={'news-items'}>
          <div>
              <div key={'news-item' + newsItem.id} className={'news-item'}>
                  <div className={'image-container'}>
                      <img className={'main-img'} src={newsItem.image}/>
                  </div>
                  <div className={'news-item-content-container'}>
                      <h2>{newsItem.title}</h2>
                      <div className={'news-type-description'}>
                          {newsItem.isImportant && (
                              <img src={window.config.theme === 'Dark' ? '/images/dark/impotant.svg' : '/images/impotant.svg'} />
                          )}
                          {newsItem.newsTypeDescription}
                      </div>
                      <p>{newsItem.text}</p>
                      {newsItem.link !== undefined && newsItem.link !== null && newsItem.link !== '' && (
                          <div className={'link-container'}>
                              <img src={'/images/link.svg'}/>
                              <a href={newsItem.link} target={'_blank'}>
                                  Подробнее
                              </a>
                          </div>
                      )}
                      <Grid>
                          <Grid.Row>
                              <Grid.Column width={8}>
                                  <div className={'for-mobile'}>
                                      {toArhiveBtn}
                                  </div>
                              </Grid.Column>
                              <Grid.Column width={8} className={'news-time-container'} style={{textAlign: 'right'}}>
                                  <img src={'/images/clock-time.svg'}/> {newsItem.date}
                              </Grid.Column>
                          </Grid.Row>
                      </Grid>
                  </div>
                  <Grid>
                      <Grid.Row>
                          <Grid.Column width={8}>
                              {newsItem.nextNewsId !== null && <Button onClick={() => navigate('/news-item/' + newsItem.nextNewsId)}>Предыдущая</Button>}
                          </Grid.Column>
                          <Grid.Column width={8} style={{textAlign: 'right'}}>
                              <span className={'for-desktop'}>{toArhiveBtn}</span>
                              {newsItem.previousNewsId !== null && <Button onClick={() => navigate('/news-item/' + newsItem.previousNewsId)}>Следующая</Button>}
                          </Grid.Column>
                      </Grid.Row>
                  </Grid>
              </div>
          </div>
      </div>
    </div>
    )
}
