import './DealerSelectInput.css';
import {Icon, Popup} from "semantic-ui-react";
import {useEffect, useState} from "react";
import SelectDealerModal from "../SelectDealerModal/SelectDealerModal";

export default function DealerSelectInput({value, valueDisplay, onChange}) {
    let [dealerSelectModal, setDealerSelectModal] = useState(false);

    useEffect(()=>{
    }, []);

    return <>
        <Popup content={'Дилер'} position={'top center'} hoverable trigger={
            <div
                className={'select-dealer-input' + (valueDisplay == null ? ' is-empty' : '')}
                onClick={() => setDealerSelectModal(true)}
            >
                {valueDisplay == null ? <span className={'empty-dealer-message'}>Выберите дилера</span> : valueDisplay}
                <Icon name={'search'} color={'grey'}/>
            </div>
        } />
        <SelectDealerModal
            open={dealerSelectModal}
            setOpen={setDealerSelectModal}
            onChange={onChange}
            dealerName={valueDisplay}
        />
    </>;
}
