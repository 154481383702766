import './CloseChatModal.css';
import {Grid, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CustomTextarea from "../../../../shared/CustomTextarea/CustomTextarea";
import {toast} from "react-toastify";
import {api} from "../../../../utils/api";
import { Rating } from 'semantic-ui-react';

export default function CloseChatModal({open, setOpen, onCloseChat, activeChatId}) {
    let [message, setMessage] = useState('');
    let [rating, setRating] = useState(0);

  useEffect(()=>{
    if(!open) {
      setMessage('');
      setRating(0);
    }
  }, [open]);

  let send = () => {
    api()
      .closeChatForDealer(activeChatId, rating, message)
      .then(resp=>{
        if(resp.ErrorInfo != '' && resp.ErrorInfo != null){
          toast.error(resp.ErrorInfo);
        }else{
          setOpen(false);
          onCloseChat();
          toast.info(resp.UserInfo);
        }
      });
  };

  const handleRate = (e, { rating }) => {
    setRating(rating);
  };

  return (
    <Modal
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
    className={'close-chat-modal'}
  >
    <Modal.Content>
      <div className={'title'}>Оцените, пожалуйста, качество оказанной Вам консультации</div>
      <div className={'rating-wrapper'}>
        <Rating
          icon='star'
          defaultRating={rating}
          maxRating={5}
          onRate={handleRate}
          className={'rating'}
          size='massive'
        />
      </div>
      <CustomTextarea value={message} label={'Укажите Ваш комментарий'} onChange={setMessage}/>
        <Grid>
          <Grid.Row>
            <Grid.Column
              width={8}
              onClick={() => {
                setOpen(false)
             }}>
              Отмена
            </Grid.Column>
            <Grid.Column
              width={8}
              onClick={send}>
              Сохранить
            </Grid.Column>
          </Grid.Row>
        </Grid>
    </Modal.Content>
  </Modal>
  );
}
