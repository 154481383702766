import './CustomInput.css';
import {Popup} from "semantic-ui-react";

export default function CustomInput({popupContent, value, 
                                      label, 
                                      onChange, 
                                      onFocus,
                                      isNumberOnly = false,
                                      disabled= false, 
                                      error= false}) {
  let inputElement =  <input
    type={isNumberOnly ? 'number' : 'text'}
    step={1000}
    min={0}
    max={999999999}
    disabled={disabled}
    className={'custom-input' + (value === '' ? '' : ' with-value') + (error ? ' error' : '')}
    placeholder={label}
    onFocus={onFocus ?? (()=>{})}
    value={value} 
    onChange={e => {
        if(isNumberOnly && e.target.value > 999999999)
            return;
        onChange(e.target.value)
    }}
  />;
  return popupContent === undefined ? inputElement : <Popup 
      position={'top center'} 
      content={popupContent} 
      hoverable 
      trigger={inputElement} />;
}