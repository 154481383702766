import './AutoLogin.css';
import {useEffect} from "react";
import {api} from "../../utils/api";

export default function AutoLogin({afterLogin}) {
  useEffect(()=>{
    if(window.location.search.indexOf('?returnUrl=/?token=') === 0){
      let token = window.location.search.replace('?returnUrl=/?token=', '');
      localStorage.clear();
      localStorage.setItem('AccessToken', token);
      api()
          .getUserInfo()
          .then((resp)=>{
            localStorage.setItem('UserId', resp.ClientId);
            localStorage.setItem('TrackAccessMode', 2);
            api()
                .trackAccess(2)
                .then(()=>{
                    window.location = '/news';
                    //afterLogin();
                });
          });
    }else{
        window.location = '/';
    }
  },[]);

  return <div><h4>Перенаправление...</h4></div>;
}
