import React, { useEffect, useState } from 'react'
import { Grid, Modal } from 'semantic-ui-react'
import { toast } from "react-toastify";

import { api } from '../../../utils/api'

import './Contacts.css'
import { AddNewContactPersonForm } from "./AddNewContactPersonForm";

const Contacts = () => {
  const [formIsVisible, setFormIsVisible] = useState(false)
  const [contacts, setContacts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [openDeleteContactModal, setOpenDeleteContactModal] = useState(null)

  // Effects
  useEffect(() => {
    setIsLoading(true)

    api()
      .getContactPersonsList()
      .then((response) => {
        setContacts(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  // Handlers
  const handleCloseForm = () => {
    setFormIsVisible(false)
  }

  const handleDeleteContact = (contactPersonId) => {
    setIsLoading(true)

    setOpenDeleteContactModal(null)

    api()
      .deleteContactPerson(contactPersonId)
      .then((response) => {
        if (!response.errorInfo) {
          toast.success(`Контактное лицо было успешно удалено!`);

          api()
            .getContactPersonsList()
            .then((response) => {
              setContacts(response)
              setIsLoading(false)
            })
        } else {
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  // Renders
  return (
    <div>
      <div className={`contacts__title_container`}>
        <h3 className={`contacts__title`}>
          Контактные лица
        </h3>

        <button
          className={`contacts__add_contact ${formIsVisible ? 'btn_disabled' : ''}`}
          style={{ backgroundColor: window.config.color }}
          onClick={() => setFormIsVisible(true)}
          disabled={formIsVisible || isLoading}
        >
          <img alt="add" src={`/images/plus-white.svg`} />
        </button>
      </div>

      {contacts.map((contact) => (
        <div key={contact.ContactPersonId} className={`contacts__container`}>
          <Grid className={`contacts__grid_container`}>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5} className={`contacts__subtitle`}>
                      Фамилия
                    </Grid.Column>
                    <Grid.Column width={11} className={`parameter_value`}>
                      {contact.Surname}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5} className={`contacts__subtitle`}>
                      Имя
                    </Grid.Column>
                    <Grid.Column width={11} className={`parameter_value`}>
                      {contact.Name}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5} className={`contacts__subtitle`}>
                      Телефон
                    </Grid.Column>
                    <Grid.Column width={11} className={`parameter_value`}>
                      {contact.Phone}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5} className={`contacts__subtitle`}>
                      E-mail
                    </Grid.Column>
                    <Grid.Column width={11} className={`parameter_value`}>
                      {contact.Email}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div
            style={{ cursor: 'pointer', marginLeft: '14px' }}
            onClick={() => setOpenDeleteContactModal(contact)}
          >
            <img
              alt={`delete-contact-${contact.ContactPersonId}`}
              src={window.config.theme === 'Dark' ? '/images/dark/delete-btn.svg' : '/images/delete-btn.svg'}
            />
          </div>
        </div>
      ))}

      {formIsVisible && (
        <AddNewContactPersonForm
          onClose={handleCloseForm}
          isUpdating={isLoading}
          setIsUpdating={setIsLoading}
          setContacts={setContacts}
        />
      )}

      {!!openDeleteContactModal && (
        <Modal
          onClose={() => setOpenDeleteContactModal(null)}
          open={!!openDeleteContactModal}
          className={'remove-account-modal'}
        >
          <Modal.Content>
            <h5 className={'title'}>
              Внимание!
            </h5>

            <p>
              Вы действительно хотите удалить контактное лицо {openDeleteContactModal.Surname} {openDeleteContactModal.Name}?
            </p>

            <Grid>
              <Grid.Row>
                <Grid.Column width={8} onClick={() => setOpenDeleteContactModal(null)}>
                  Нет
                </Grid.Column>

                <Grid.Column
                  width={8}
                  style={{ color: window.config.color }}
                  onClick={() => handleDeleteContact(openDeleteContactModal.ContactPersonId)}
                >
                  Да
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}

export { Contacts }
