import './ServiceRequest.css';
import {useParams} from "react-router-dom";
import {Grid, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CustomDropdown from "./components/CustomDropdown/CustomDropdown";
import CustomTextarea from "../../shared/CustomTextarea/CustomTextarea";
import DealerSelectInput from "../../shared/DealerSelectInput/DealerSelectInput";
import {api} from "../../utils/api";
import CustomInput from "../../shared/CustomInput/CustomInput";

export default function ServiceRequest({userPersonalDealerId, userPersonalDealerName, onChangeDealer}) {
  let { car } = useParams();
  let [selectedCarId, setSelectedCarId] = useState(null);
  let [repairTypeId, setRepairTypeId] = useState(null);

  let [repairTypes, setRepairTypes] = useState([]);

  let [cars, setCars] = useState([]);
  let [slots, setSlots] = useState([]);
  let [selectedYear, setSelectedYear] = useState();

  let [selectedDate, setSelectedDate] = useState();
  let [selectedTime, setSelectedTime] = useState();
  let [comment, setComment] = useState('');
  let [viewSuccessCreateServiceRequestModal, setViewSuccessCreateServiceRequestModal] = useState(false);

  let [confirmRemoveServiceRequestModal, setConfirmRemoveServiceRequestModal] = useState(false);

  let [currentServiceRequest, setCurrentServiceRequest] = useState(null);

  let [carNumber, setCarNumber] = useState('');
  let [carNumberError, setCarNumberError] = useState(false);

  let [miliage, setMiliage] = useState('');
  let [miliageError, setMiliageError] = useState(false);

  let [viewErrorCreateServiceRequestModal, setViewErrorCreateServiceRequestModal] = useState(false);
  let [viewErrorMessageForModal, setViewErrorMessageForModal] = useState('');

  const [isReplacementCarRequired, setIsReplacementCarRequired] = useState(null)

  let init = () => {
    api()
        .getCurrentServiceRequest()
        .then(resp=>{
          if(resp.length > 0){
            setCurrentServiceRequest(resp[0]);
          }else{
            setCurrentServiceRequest(null);
            api()
                .getServiceRequestTypes()
                .then(repairTypesFromServer=>{
                  setRepairTypes(repairTypesFromServer.map(x=> ({id: x.CategoryId, name: x.Descr})));
                  api()
                      .getCars()
                      .then(carsFromServer=>{
                        setCars(carsFromServer.map(car => ({
                          id: car.CarId,
                          name: car.CarDescr,
                          number: car.LicencePlate
                        })));

                        if(carsFromServer.length === 1){
                          setSelectedCarId(carsFromServer[0].CarId);
                          setCarNumber(carsFromServer[0].LicencePlate);
                        }
                        if(car !== null && car !== undefined){
                          setSelectedCarId(parseInt(car));
                          setCarNumber(carsFromServer.filter(x=>x.CarId === parseInt(car))[0].LicencePlate);
                          setRepairTypeId(repairTypesFromServer[0].CategoryId);
                          document.getElementById('comment').focus();
                        }
                      });
                });
          }
        });
  };
  useEffect(()=>{
    init();

    let availableSlots = [];

    let tempDateTime = new Date();

    tempDateTime.setHours(tempDateTime.getHours() + 24);
    tempDateTime.setHours(8);
    tempDateTime.setMinutes(30);

    for(let i = 0; i < 216 ; i ++) {
      tempDateTime.setMinutes(tempDateTime.getMinutes()+30);
      if(tempDateTime.getHours() >= 21)
        tempDateTime.setHours(tempDateTime.getHours() + 12);

      let year = tempDateTime.getFullYear()
      let date = ((tempDateTime.getDate() < 10 ? '0' : '') + tempDateTime.getDate()) + '.' + (((tempDateTime.getMonth() + 1) < 10 ? '0' : '') + (tempDateTime.getMonth() + 1))
      let time = ((tempDateTime.getHours() < 10 ? '0' : '') + tempDateTime.getHours()) + ':' + ((tempDateTime.getMinutes() < 10 ? '0' : '') + tempDateTime.getMinutes())
      if(availableSlots.filter(x=>x.year === year).length === 0)
        availableSlots.push({year: year, dates: []})
      let yearSlot = availableSlots.filter(x=>x.year === year)[0];
      if(yearSlot.dates.filter(x=>x.date === date).length === 0)
        yearSlot.dates.push({date: date, slots: []})
      let dateSlot = yearSlot.dates.filter(x=>x.date === date)[0];
      if(dateSlot.slots.filter(x=>x.time === time).length === 0)
        dateSlot.slots.push({time: time})
    }
    setSlots(availableSlots);
    setSelectedYear(availableSlots[0].year);

    setSelectedDate(availableSlots[0].dates[0].date);
    setSelectedTime(availableSlots[0].dates[0].slots[0].time);
  }, []);

  let createServiceRequest = ()=>{
    if (carNumber === ''){
      setCarNumberError(true);
      return;
    }

    if (miliage === ''){
      setMiliageError(true);
      return;
    }

    api()
        .createServiceRequest(selectedCarId, carNumber, miliage, repairTypeId, selectedDate + '.' + selectedYear, selectedTime, comment, isReplacementCarRequired)
        .then(resp=>{
          if(resp.ErrorInfo == null || resp.ErrorInfo == '') {
            setViewSuccessCreateServiceRequestModal(true);
            init();
          }else{
            setViewErrorMessageForModal(resp.ErrorInfo);
            setViewErrorCreateServiceRequestModal(true);
          }
        });
  };

  let deleteServiceRequest = ()=>{
    api()
        .deleteServiceRequest()
        .then(()=>{
          setConfirmRemoveServiceRequestModal(false);
          setComment('');
          setRepairTypeId(null);
          setSelectedCarId(null);

          setMiliage('');
          setSelectedYear(slots[0].year);
          setSelectedDate(slots[0].dates[0].date);
          setSelectedTime(slots[0].dates[0].slots[0].time);
          init();
        });
  };
  return <div>
    <div className={'service-request-page'}>
      <Grid className={'header'}>
        <Grid.Row>
          <Grid.Column width={16}><h1>Запись на сервис</h1></Grid.Column>
        </Grid.Row>
      </Grid>
      {currentServiceRequest !== null ? <>
        <div className={'service-request-item'}>
          <Grid className={'item-properties'}>
            <Grid.Row><Grid.Column width={8}>Статус заявки:</Grid.Column><Grid.Column width={8}><span className={'approve-state'}>{currentServiceRequest.StateDescr}</span></Grid.Column></Grid.Row>
            <Grid.Row><Grid.Column width={8}>Мой автомобиль:</Grid.Column><Grid.Column width={8}>{currentServiceRequest.CarDescr}</Grid.Column></Grid.Row>
            <Grid.Row><Grid.Column width={8}>Вид ремонта:</Grid.Column><Grid.Column width={8}>{currentServiceRequest.CategoryDescr}</Grid.Column></Grid.Row>
            <Grid.Row><Grid.Column width={8}>Дилер:</Grid.Column><Grid.Column width={8}>{currentServiceRequest.DealerDescr}</Grid.Column></Grid.Row>

            {currentServiceRequest.AgreedDate ? (<Grid.Row>
              <Grid.Column width={8}>
                Согласованная дата:
              </Grid.Column>
              <Grid.Column width={8}>
                <img
                  src={window.config.theme === 'Dark' ? '/images/dark/calendar-small.svg' : '/images/calendar-small.svg'}
                  alt="calendar"
                />
                {currentServiceRequest.AgreedDate}
              </Grid.Column>
            </Grid.Row>
              ) : (
              <Grid.Row>
                <Grid.Column width={8}>
                  Желаемая дата и время:
                </Grid.Column>
                <Grid.Column width={8}>
                  <img
                    src={window.config.theme === 'Dark' ? '/images/dark/calendar-small.svg' : '/images/calendar-small.svg'}
                    alt="calendar"
                  />
                  {currentServiceRequest.WishDate} {currentServiceRequest.WishTime}
                </Grid.Column>
              </Grid.Row>
            )}

          </Grid>
          <button className={'cancel-request-btn'} onClick={()=>{setConfirmRemoveServiceRequestModal(true)}}>Отменить заявку</button>
        </div>
        <p className={'description-for-service-request-item'}>Сделать запись на сервис можно только <b>однократно</b>!<br/>
          Чтобы перезаписаться, отмените заявку и запишитесь снова</p>
      </> : <div className={'service-request-form'}>
        <DealerSelectInput
            value={userPersonalDealerId}
            valueDisplay={userPersonalDealerName}
            onChange={onChangeDealer}
        />
        <CustomDropdown
            popupContent={'Автомобиль'}
            label={'Мой автомобиль'}
            value={selectedCarId}
            options={cars}
            onChange={val => {
              setSelectedCarId(val);
              setCarNumber(cars.filter(x=>x.id === val)[0].number);
            }}
        />
        {selectedCarId !== null ? <CustomInput
            popupContent={'Гос. номер'}
            label={'Гос. номер'}
            value={carNumber}
            onChange={setCarNumber}
            error={carNumberError}
            onFocus={()=>{setCarNumberError(false)}}
        /> : ''}
        {selectedCarId !== null ? <CustomInput
            popupContent={'Пробег'}
            label={'Пробег (км.)'}
            value={miliage}
            onChange={setMiliage}
            error={miliageError}
            isNumberOnly
            onFocus={()=>{setMiliageError(false)}}
        /> : ''}
        <CustomDropdown
            popupContent={'Вид ремонта'}
            label={'Вид ремонта'}
            value={repairTypeId}
            options={repairTypes}
            onChange={val => setRepairTypeId(val)}
        />
        {userPersonalDealerId == null || selectedCarId == null || repairTypeId == null ? '' : <div className={'slot-selector'}>
          <div className={'year-select'}>
            <div className={'select-date-description'}>Выберите желаемую дату</div>
            {slots.length > 1 ? slots.map(x=> <div
                className={'year' + (x.year === selectedYear ? ' active' : '')}
                onClick={()=>{
                  setSelectedYear(x.year);
                  setSelectedDate(x.dates[0].date);
                  setSelectedTime(x.dates[0].date.slots[0].time);
                }}
            >{x.year} год</div>) : ''}
          </div>
          <div className={'dates'}>
            {slots.filter(x=>x.year === selectedYear)[0].dates.map(date => <div
                onClick={()=>{
                  setSelectedDate(date.date);
                  setSelectedTime(date.slots[0].time);
                }}
                className={'date' + (date.date === selectedDate ? ' active' : '')}
            >{date.date}</div>)}
          </div>
          {selectedYear !== null && selectedDate !== null ? <div className={'select-slot-title'}>Выберите время</div> : ''}
          <div className={'slots'}>
            {slots.filter(x=>x.year === selectedYear)[0].dates.filter(x=>x.date === selectedDate)[0].slots.map(slot => <div
                className={'slot' + (slot.time === selectedTime ? ' active' : '')}
                onClick={()=>{
                  setSelectedTime(slot.time);
                }}
            >{slot.time}</div>)}
          </div>
        </div>}

        {!window.location.hostname.includes('chery') && (
          <CustomDropdown
            popupContent={'Требуется подменный автомобиль'}
            label={'Требуется подменный автомобиль'}
            value={isReplacementCarRequired}
            options={[
              { id: true, name: 'Требуется подменный автомобиль'},
              { id: false, name: 'Подменный автомобиль не требуется'},
            ]}
            onChange={(val) => setIsReplacementCarRequired(val)}
          />
        )}
        {isReplacementCarRequired && (
            <p>
              Решение об одобрении или отказе принимает дилер. Данный запрос не является гарантией получения подменного автомобиля.
            </p>
        )}

        <CustomTextarea id={'comment'} value={comment} label={'Примечание'} onChange={setComment}/>

        <button
            disabled={!!(!selectedCarId || !repairTypeId || !userPersonalDealerId)}
            className={'send-service-request'}
            onClick={createServiceRequest}
        >Записаться на сервис</button>
      </div>}
    </div>

    <Modal
      onClose={() => setViewSuccessCreateServiceRequestModal(false)}
      onOpen={() => setViewSuccessCreateServiceRequestModal(true)}
      open={viewSuccessCreateServiceRequestModal}
      className={'success-create-serviceRequest-modal'}
    >
      <Modal.Content>
        <img src={window.config.theme === 'Dark' ? '/images/dark/success-create.svg' : '/images/success-create.svg'}/>
        {window.location.hostname.includes('chery') ? (
          <>
            <div className={'title'}>Ваша заявка принята!</div>
            <p>Для подтверждения записи менеджер дилерского центра свяжется с Вами в ближайшее время.</p>
          </>
        ) : (
          <>
            <div className={'title'}>Вы успешно записались на сервис!</div>
            <p>В ближайшее время с вами свяжется оператор для уточнения деталей</p>
            <p className={'important'}>На указанный e-mail Вам придет письмо с информацией о записи</p>
          </>
        )}


      </Modal.Content>
    </Modal>

    <Modal
      onClose={() => setViewErrorCreateServiceRequestModal(false)}
      onOpen={() => setViewErrorCreateServiceRequestModal(true)}
      open={viewErrorCreateServiceRequestModal}
      className={'success-create-serviceRequest-modal'}
    >
      <Modal.Content>
        <div className={'title'}>Ошибка!</div>
        <p>{viewErrorMessageForModal}</p>
      </Modal.Content>
    </Modal>

    <Modal
        onClose={() => setConfirmRemoveServiceRequestModal(false)}
        onOpen={() => setConfirmRemoveServiceRequestModal(true)}
        open={confirmRemoveServiceRequestModal}
        className={'confirm-remove-serviceRequest-modal'}
    >
      <Modal.Content>
        <div className={'title'}>Внимание!</div>
        <p>Вы действительно хотите отменить заявку?</p>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} onClick={()=>{setConfirmRemoveServiceRequestModal(false)}}>Нет</Grid.Column>
            <Grid.Column width={8} onClick={deleteServiceRequest}>Да</Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  </div>;
}
