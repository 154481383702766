import './CustomRadio.css';

export default function CustomRadio({value, label, onChange, isComplete}) {
  const getImgTheme = () => window.config.theme === 'Dark' ? '/images/dark/radio-' : '/images/radio-'

  return (
    <div
      className={'custom-checkbox-container' + (isComplete ? ' is-complete' : '')}
      onClick={() => onChange(!value)}
    >
      <img className={'checkbox-image'} src={(getImgTheme() + (value ? 'true' : 'false') +'.svg')}/>
      {label}
    </div>
  )
}
